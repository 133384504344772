const menuData = [
  {
    name: 'WikiBudget',
    to: '/wikibudget/d79e976b-4dba-4e82-9078-16056b2fb91f', // hardcode to Test db Climate Swarm group for now
    icon: ['fad', 'users'],
    only: ['foo', 'climate']
  },
  {
    name: 'Bioregions',
    to: '/bioregions',
    only: ['whoknows'],
    icon: ['fad', 'comment-smile']
  },
  {
    name: 'Timeline',
    to: '/timeline',
    icon: ['fad', 'users'],
    only: ['greencheck', 'whoknows']
  },
  {
    name: 'import',
    to: '/import',
    only: ['greencheck', 'netcoop', 'whoknows', 'portable'],
    icon: ['fad', 'comment-smile']
  },
  {
    name: 'Claim',
    to: '/claim',
    only: ['greencheck', 'whoknows', 'netcoop', 'portable'],
    icon: ['fad', 'comment-smile']
  },
  {
    name: 'OneEarth',
    to: '/oneearth',
    only: ['foo'],
    icon: ['fad', 'comment-smile']
  },

  {
    name: 'Inquiries',
    to: '/search_decisions',
    only: [
      'commonsgraph',
      // 'greencheck',
      'blueswarm',
      'demo',
      'psyche',
      'emerge',
      'noonet',
      'nao',
      'kernel'
    ]
  },
  {
    name: 'Portfolios',
    to: '/search_decisions',
    only: ['newday'],
    icon: ['fad', 'chart-network']
  },
  {
    name: 'Network',
    to: '/search_network',
    icon: ['fad', 'users'],
    not: ['foo', 'greencheck']
  },
  {
    name: 'Praise',
    to: '/respect',
    icon: ['fad', 'users'],
    only: ['greencheck', 'climate']
  },

  {
    name: 'Bookmarks',
    to: '/bookmarks',
    icon: ['fad', 'users'],
    only: ['best_of', 'perspectiva', 'demo', 'emerge', 'climate', 'noonet', 'nao', 'kernel']
  },
  {
    name: 'Feed',
    to: '/bookmarks',
    icon: ['fad', 'users'],
    only: ['newday']
  },
  {
    name: 'News',
    to: '/headlines',
    icon: ['fad', 'users'],
    only: ['demo']
    // not: []
  },
  {
    name: 'Listings',
    to: '/search_listings',
    only: ['sharebnb'],
    icon: ['fad', 'search-location']
  },
  {
    name: 'Invite',
    to: '/invite',
    only: ['foo'],
    icon: ['fad', 'comment-smile']
  },
  {
    name: 'Share',
    to: '/share',
    only: ['best_of', 'perspectiva', 'demo', 'kernel', 'newday'],
    icon: ['fad', 'share-alt']
  },

  {
    name: 'Discuss',
    to: '/discussion',
    only: ['demo'],
    icon: ['fad', 'comment-smile']
  },
  {
    name: 'About',
    to: '/about',
    only: ['greencheck', 'netcoop'],
    icon: ['fad', 'comment-smile']
  }
];

const deprecated = [
  {
    name: 'My Places',
    to: '/add_listing',
    only: ['sharebnb'],
    icon: ['fad', 'map-marker-alt']
  }
];

export default menuData;
