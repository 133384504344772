import React from 'react';
import { useMemo } from 'react';
import { Button, Flex, Link, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import useNoo from 'hooks/useNoo';
import menuData from 'shared/constants/menuData';
import FontAwesomeIcon from 'utils/FontAwesomeLoader';
import GroupSelector from 'components/Segments/GroupSelector';

const NavButton = styled(Button)`
  margin-left: 0.5em;
  margin-right: 0.5em;
  padding: 3px 6px;
  line-height: 0.5em;
  height: 2em;
`;

const Nav = () => {
  const { googleUser: user, currentNetwork, currentNetworkData } = useNoo('TopMenu');
  const network = currentNetworkData;
  const netname = network.id;
  const history = useHistory();

  const localMenu = useMemo(() => {
    const myMenu = [];
    const check = [];

    if (currentNetwork) {
      check.push(currentNetwork);
    }

    if (user) {
      check.push('_user');
    }
    const tab_order =
      network.tab_order?.length > 0
        ? network.tab_order
        : menuData.map(one => one.name.toLowerCase());

    menuData.sort((a, b) => {
      const aidx = tab_order.indexOf(a.name.toLowerCase());
      const bidx = tab_order.indexOf(b.name.toLowerCase());
      const srt = aidx >= 0 && bidx >= 0 ? aidx - bidx : bidx - aidx;
      return srt; // go by specific order set in config/index.js otherwise original order
    });

    menuData.forEach(item => {
      if (item.not && item.only) {
        throw new Error('misconfig, cannot have both a not and only list');
      }
      // no filter, let it pass
      if (!item.not?.length && !item.only?.length) {
        myMenu.push(item);
      }
      if (item.not) {
        let pass = true;
        check.forEach(name => {
          if (item.not.indexOf(name) > -1) {
            pass = false;
          }
        });
        if (pass) {
          myMenu.push(item);
        }
      }
      if (item.only) {
        let pass = false;
        check.forEach(name => {
          if (item.only.indexOf(name) > -1) {
            pass = true;
          }
        });
        if (pass) {
          myMenu.push(item);
        }
      }
    });
    return myMenu;
  }, [currentNetwork, user, network]);
  //leftIcon={icon}

  const onSelectGroup = (e, groupId) => {
    history.push('/community?group=' + groupId);
  };

  return netname == 'portable' ? (
    <Flex alignItems='center' justifyContent={['left', 'left', 'center']}>
      <h3>Choose a community</h3>
      <GroupSelector
        callback={onSelectGroup}
        label={'Choose Group'}
        render={'selector'}
        filters={['all']}
      />
    </Flex>
  ) : (
    <Flex
      className='nav'
      m={['1em 10px', '1em auto']}
      alignItems='center'
      justifyContent={['left', 'left', 'center']}
      width={['100%', '85%', '65%']}
    >
      {localMenu.map((item, idx) => {
        const icon =
          item.icon &&
          FontAwesomeIcon({
            icon: item.icon,
            name: item.name,
            style: { marginTop: '2px', marginInlineEnd: 0 }
          });
        return (
          <Link
            as={RouterLink}
            to={item.to}
            key={idx}
            _hover={{ textDecoration: 'none' }}
            _focus={{ outline: 0 }}
          >
            <NavButton
              fontWeight='inherit'
              textTransform='uppercase'
              fontSize='14px'
              _focus={{ outline: 0 }}
              _hover={{ backgroundColor: 'purple.400', color: 'purple.900' }}
              backgroundColor='purple.700'
              color='white'
            >
              {item.name}
            </NavButton>
          </Link>
        );
      })}
    </Flex>
  );
};

export default Nav;
