import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { authorizeFan, logoutFan, setDeepLinkLogin } from 'redux/actions/fanAuth';
import useNoo from './useNoo';

// singleton-ish behavior if this hook is included in multiple places (it might be!)
let gettingUserData = false;
let hasCheckedOnce = false;
let gettingDeepUrl = false;

// static token for the page session (can make this more dynamic later)
const fanPreauthToken = btoa(Math.round(Date.now() * Math.random() * 1000000) + '')
  .replace(/=/g, '')
  .toLowerCase();

// deep link fetcher / maker
const fetchFanLoginToken = async siteSettings => {
  // if there's no fanLoginDomain, then we are not set up yet
  if (!siteSettings?.fanLoginDomain) return;
  gettingDeepUrl = true;
  try {
    const response = await fetch(
      `/api/fan/preauth?token=${fanPreauthToken}&host=${window.location.host}`
    );
    if (response.ok) {
      const json = await response.json();
      if (json.token) {
        gettingDeepUrl = false;
        return json.token;
      } else {
        console.log('no token back from fan preauth request: ', json);
      }
    } else {
      console.log('the response to get fan pre-auth was not ok!');
      console.log(response);
    }
  } catch (error) {
    console.log('there was an error setting up fan preauth');
  }
  gettingDeepUrl = false;
  return null;
};

const useFanAuth = () => {
  const { user, did, deepLinkLogin, token } = useSelector(state => state.fanAuth);
  const { siteSettings } = useNoo();
  const dispatch = useDispatch();
  const history = useHistory();

  const authorize = useCallback(
    payload => {
      dispatch(authorizeFan(payload));
    },
    [dispatch]
  );

  const logout = () => {
    dispatch(logoutFan());
    // also, fetch to server to kill session tokens
    // fire and forget for now, nothing special happens except server kills session tokens
    fetch('/api/fan/kill');
  };

  // regenerate on demand if you want a fresh one:
  const regenerateFanLoginDeepLink = useCallback(async () => {
    const token = await fetchFanLoginToken(siteSettings);
    if (token) {
      const deepLink = `fan://auth?cb=${encodeURIComponent(
        `${siteSettings.fanLoginDomain}/api/fan/auth?tok=${token}`
      )}`;
      dispatch(setDeepLinkLogin({ deepLink, token }));
    } else {
      dispatch(setDeepLinkLogin(''));
    }
  }, [dispatch, siteSettings]);

  // this is effectively the same as the use-e in useFirebaseAuth
  // it will ping the server once to check for a current session, when the browser loads (or refreshes)
  useEffect(() => {
    async function run() {
      if (!user && !gettingUserData && !hasCheckedOnce) {
        try {
          // no user, let's see if we have one in session.
          const response = await fetch(`/api/fan/refresh-user`);
          if (response.ok) {
            // const
            const data = await response.json();
            if (data.success) {
              authorize(data);
            } else {
              // console.log('no user found in refresh request');
              // console.log(data);
            }
          } else {
            console.log('error calling fetch for FAN user in session');
            console.log(response);
          }
          hasCheckedOnce = true;
          gettingUserData = false;
        } catch (error) {
          console.log('error checking for FAN user in session');
          console.log(error);
          gettingUserData = false;
        }
      }

      // only get the deep link if there's no user
      if (siteSettings && !deepLinkLogin && !user && !gettingUserData && !gettingDeepUrl) {
        await regenerateFanLoginDeepLink();
      }
    }

    run();
  }, [authorize, user, dispatch, deepLinkLogin, siteSettings, regenerateFanLoginDeepLink]);

  const claimOtt = async (ott, setError = () => {}) => {
    try {
      const body = JSON.stringify({ ott });
      console.log('fetching body', body);
      const response = await fetch('/api/fan/start-session', {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body
      });
      if (response.ok) {
        const data = await response.json();
        if (data.success) {
          console.log('alright we are logged in as a FAN user');
          console.log(data);
          // call the hook method to set up the local flags saying we're logged in
          // pass in the user data from start-session
          authorize(data);
          // TODO: expand config and query params to fan to set a landing /path/foo
          history.push('/');
        } else {
          console.log('hmmm something happened. check the request or the server');
          setError('hmmm something happened. check the request or the server');
        }
      } else {
        console.log('response from start-session failed');
        setError('response from start-session failed');
      }
    } catch (e) {
      console.log('error starting the fan session');
      console.log(e);
      setError('error starting the fan session: ' + e.toString());
    }
  };

  const checkForOtt = async (setError = () => {}) => {
    if (!token) return;
    try {
      const body = JSON.stringify({ token });
      const response = await fetch('/api/fan/get-ott', {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body
      });
      if (response.ok) {
        const data = await response.json();
        if (data.ott) {
          await claimOtt(data.ott);
        }
      } else {
        console.log('response to get ott was not ok!');
      }
    } catch (e) {
      console.log('error checking for the fan ott');
      console.log(e);
      setError('error checking for the fan ott: ' + e.toString());
    }
  };

  return {
    deepLinkLogin,
    fanPreauthToken: token,
    checkForOtt,
    claimOtt,
    regenerateFanLoginDeepLink,
    authorize,
    logout
  };
};

export default useFanAuth;
