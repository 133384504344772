import React from 'react';
import { useEffect } from 'react';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
// import ReactMarkdown from 'react-markdown';
import { Text, Heading, Box, Flex } from '@chakra-ui/react';
import RenderChakraMarkdown from 'utils/ChakraMarkdown';
import useNoo from 'hooks/useNoo';
import SignInOrRegister from 'components/Segments/SignInOrRegister';
import ValidUser from 'components/Segments/ValidUser';
import BetaNotice from 'components/Segments/BetaNotice';
import InvitationsAccept from 'components/Invitations/InvitationsAccept';
import TagCloud from 'components/Segments/TagCloud';
import MarkdownAccordion from 'components/Segments/MarkdownAccordion';
import Videos from './Videos';

// function sleep(ms) {
//   return new Promise(resolve => setTimeout(resolve, ms));
// }

function useQuery() {
  var params = new URLSearchParams(useLocation().search);
  var lookup = {};
  for (var pair of params.entries()) {
    lookup[pair[0]] = pair[1];
  }
  return lookup;
}

const Splash = () => {
  const { googleUser: user, currentNetworkData, nooUser, fanUser, isAuthorized } = useNoo('Splash');
  const network = currentNetworkData;
  const netname = network.id;
  const isGreencheck = netname == 'greencheck';
  console.log('noou', nooUser);
  const valid = (nooUser?.valids || []).length > 0 && (nooUser?.claimed || []).length > 0;

  // TODO: if using invitations here, update to useInvitations() hook
  // const onCallAcceptInvitation = () => {
  // 	nooApi.acceptInvitation(user, invitationId, setAcceptLoading, setAcceptResponse);
  // };

  let params = useQuery();
  var invitationId = params.invite;

  if (invitationId) {
    localStorage.setItem('invitationId', invitationId);
  } else {
    // TBD: check local storage and if there, check it's still valid by reading it
    const stored = localStorage.getItem('invitationId');
    if (stored) {
      /* CHECK INVITE IS LEGIT HERE, AND SET OR REMOVE ON RESPONSE, BUT I DON'T KNOW HOW TO HANDLE THE RESPONSE
      // TODO: if using noo Api here, update to useNooApi() hook
			const aqlQuery = 'return document("' + invitationId + '")';
			localStorage.setItem('invitation', invite);
			nooApi.aqlSearch({user, aqlQuery, setLoading, setResponse});
			*/
      invitationId = stored;
    }
  }

  const display = network?.display;
  const paras = network.welcome;
  const markdown = network.markdown;
  var items = [];
  if (nooUser && isGreencheck) items.push(<BetaNotice key='beta' valid={valid} />);
  if (isGreencheck) {
    items.push(<ValidUser key='valid' user={nooUser} valid={valid} />);
    items.push(<Videos key='videos' />);
  }

  /*   useEffect(() => { // doesn't work yet
    Array.from(document.links)
      .filter(link => {
        console.log('link', link);
        link.hostname != window.location.hostname;
      })
      .forEach(link => (link.target = '_blank'));
  }, [items]); */

  if (markdown) {
    items.push(<RenderChakraMarkdown key='mkdown'>{markdown}</RenderChakraMarkdown>);
  } else if (paras) {
    _.forEach(paras, (value, key) => {
      items.push(
        <Text key={key} fontSize={['.8em', '1.2em']}>
          {value}
        </Text>
      );
    });
  }

  const tagline = network?.tagline; // || 'Welcome to ' + display;
  return (
    <Flex
      flexDirection='column'
      alignItems='center'
      margin='1em auto'
      width='90vw'
      borderRadius='lg'
      boxShadow='0px 1px 4px 0px rgba(0, 0, 0, 0.5)'
      padding='1em'
      bgColor='splashBg'
    >
      {tagline && (
        <Heading as='h2' size='md'>
          {tagline}
        </Heading>
      )}
      <Flex sx={{ w: '100%' }}>
        <InvitationsAccept />
      </Flex>
      {!isAuthorized && <SignInOrRegister showHeader={false} />}

      <Box className='App-card'>
        <Box id='welcome'>{items}</Box>
      </Box>
    </Flex>
  );
};

export default Splash;
