import React from 'react';
import { useEffect, useMemo, useState, useCallback } from 'react';
import _ from 'lodash';
import { Box, Link, Text, Image, VStack, Tooltip, IconButton, Button } from '@chakra-ui/react';
import { ArrowUpIcon, ArrowDownIcon } from '@chakra-ui/icons';

import SortableTable from 'components/Segments/SortableTable';
import PostLink from 'components/Invitations/PostLink';
import useSearchResults, { ResultLink } from 'hooks/useSearchResults';
import { truncateTimestamp } from 'utils';
import _SERVICES from 'shared/constants/socialServices';
import { PopOverUser } from 'components/Segments/UserCard';
import TagPersonModal from 'components/Segments/TagPersonModal';
import NominateModal from 'components/Segments/NominateModal';

const SearchResultsEdges = props => {
  const kind = 'edges';
  // group_id was pushed from SearchHeadlines, but doesn't appear to be used in SearchResults anywhere?
  const { data } = props;
  const [parsedData, setParsedData] = useState(null);
  const [peopleLookup, setPeopleLookup] = useState(null);
  const {
    defaultColumnProperties,
    map_column_title,
    map_column_sx,
    get_columns
  } = useSearchResults(kind);

  const onUp = _id => {
    console.log('up', _id);
  };
  const onDown = _id => {
    console.log('down', _id);
  };

  const decorate = useCallback(user => {
    let fullname = user.fullname;
    let decorated = fullname;
    let image = user.image || user.avatar;
    Object.keys(_SERVICES).forEach(svc => {
      if (user[svc]) {
        fullname = fullname || user[svc]?.fullname;
        decorated = fullname + ' (' + svc + ')';
      }
      image = image || user[svc]?.image || user[svc]?.avatar;
    });
    // return fullname with attribution and image
    const email_from = profile => {
      const email = profile?.email || '';
      const mail_server = email ? email.split('@') : [];
      return mail_server.length > 1 ? '@' + mail_server[1] : '';
    };

    return [decorated, image];
  }, []);

  useEffect(() => {
    if (data) {
      const it = data?.result?.[0] || data;
      setParsedData(it);
      let profiles = {}; // make lookup for profiles
      const people = it?.nodes || [];
      if (people.length > 0 && !peopleLookup) {
        _.forEach(people, one => {
          if (one) {
            profiles[one.id] = one;
          }
        });
        let pids_from_edges = new Set();
        data.links.forEach(edge => {
          pids_from_edges.add(edge._from || edge.source);
          pids_from_edges.add(edge._to || edge.target);
        });
        pids_from_edges = Array.from(pids_from_edges);
        pids_from_edges.sort();
        let pids_from_profiles = Object.keys(profiles);
        pids_from_profiles.sort();
        const missing = pids_from_edges.filter(pid => {
          return pids_from_profiles.indexOf(pid) == -1;
        });
        if (missing.length > 0)
          console.log(
            'Edges contain missing users;',
            Object.keys(profiles).length,
            'nodes',
            pids_from_edges.size,
            'found. Missing =',
            missing
          );
        setPeopleLookup(profiles);
      }
    }
  }, [data, setParsedData, peopleLookup, setPeopleLookup, decorate]);

  const onNameHover = e => {
    console.log('name hover', e.target);
    e.target.click();
  };

  const OnePop = props => {
    const src = props.source;
    const order = [
      ...['avatar', 'description', 'location', 'bioregion'],
      ...Object.keys(_SERVICES)
    ];
    return (
      <PopOverUser
        key={src._id}
        _id={src._id}
        node={src}
        tooltip={props.tooltip}
        order={order}
        onClick={event => {
          props.onclick({
            _id: event.target.dataset.id,
            fullname: event.target.dataset.fullname
          });
        }}
        // onHover={onNameHover}
      />
    );
  };

  const Target = props => {
    if (props.type == 'RESPECT') {
      return (
        <Tooltip label={'Praise ' + props.fullname}>
          {props.network == 'whoknows' ? (
            <NominateModal target={props.node} prefix={props.fullname} />
          ) : (
            <TagPersonModal disabled={true} target={props.node} prefix={props.fullname} />
          )}
        </Tooltip>
      );
    } else if (['VALIDATE', 'NOMINATE'].includes(props.type)) {
      let node = { ...props.node };
      node.fullname = props.fullname || node.fullname;
      return <OnePop source={node} tooltip={props.tooltip} />;
    } else {
      return <Text title={props.type}>{props.fullname}</Text>;
    }
  };

  const edges = useMemo(() => {
    const _ACTION_LOOKUP = {
      VALIDATE: 'Validated',
      CLAIM: 'Claimed',
      RESPECT: props.network == 'whoknows' ? 'Amplified' : 'Praised',
      FOLLOW: 'Followed',
      NOMINATE: 'Nominated'
    };
    const edges = parsedData?.links || [];
    if (edges && peopleLookup) {
      const find_url = new RegExp('//(.*?)/');
      return (
        edges
          .map((edge, index) => {
            const { id, type, data, source, target, ts_create, ts_mod, qualifier } = edge;
            const timestamp = truncateTimestamp(ts_mod); // ts_create
            if (source && target && type) {
              let src = peopleLookup[source];
              const sid = source._id;
              let tgt = peopleLookup[target];
              const [decorated] = decorate(tgt);

              return {
                from: <OnePop source={src} />,
                fromSorter: source.decorated,
                to: (
                  <Target
                    fullname={decorated || tgt.fullname}
                    node={tgt}
                    type={type}
                    tooltip={qualifier}
                    network={props.network}
                  />
                ),
                pic: <img src={src.image} title={src.fullname} width={'50px'} height={'50px'} />,
                ts: (
                  <Text
                    as='span'
                    width={['20%', '20%', '20%']}
                    fontSize={['11px', '13px', '14px']}
                    backgroundColor='cyan.700'
                    color='cyan.200'
                    fontWeight='bold'
                    p='2px 4px'
                    mx={[0, '4px', '10px']}
                    borderRadius='5px'
                    title={[edge.id, source, target].join(' | ')}
                  >
                    {timestamp}
                  </Text>
                ),
                tsSorter: -ts_mod,
                type: _ACTION_LOOKUP[type]
              };
            }
            return null;
          })
          // lastly, filter out any falsy's from map()
          .filter(result => !!result)
      );
    }
    return null;
  }, [props.network, parsedData?.links, peopleLookup, decorate]);

  const cols = useMemo(() => {
    if (edges?.length) {
      return get_columns(edges[0]).map(key => {
        return {
          ...defaultColumnProperties,
          key: key,
          name: map_column_title(_.capitalize(key), kind),
          sx: map_column_sx(key, kind)
        };
      });
    }
    return [];
  }, [defaultColumnProperties, get_columns, edges, map_column_sx, map_column_title]);

  if (!_.size(edges)) {
    return null;
  }
  return (
    <Box mt='20px'>
      <SortableTable
        columns={cols}
        rows={edges}
        headRowSx={{ backgroundColor: 'cyan.900' }}
        resultsCellSx={{ padding: '4px 4px 0 0', a: { pt: 0 } }}
      />
    </Box>
  );
};

export default SearchResultsEdges;
