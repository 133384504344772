import React, { useState, useRef, useMemo, useCallback, useEffect } from 'react';
import {
  Box,
  Flex,
  Link,
  HStack,
  FormLabel,
  Tooltip,
  Text,
  Image,
  UnorderedList,
  ListItem,
  Heading
} from '@chakra-ui/react';
import _ from 'lodash';
import styled from '@emotion/styled';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import useQuery from 'utils/useQuery';
import useNoo from 'hooks/useNoo';
import useNooApi from 'hooks/useNooApi';
import UserCard, { ServiceLink } from 'components/Segments/UserCard';
import InvitationsAccept from 'components/Invitations/InvitationsAccept';

import UserAuth from 'components/User/UserAuth';
import ShowNetwork from 'components/Networks/ShowNetwork';
// import ShowD3Network from 'components/Networks/ShowD3Network';
import ShowForceNetwork from 'components/Networks/ShowForceNetwork';
import { graph_normalize } from 'utils/graphNormalize';
import { normalize_id, capitalize } from 'utils';
import ClaimPopover from '../Segments/ClaimPopover';
import ClaimBookmarklet from 'shared/constants/claimBookmarklet';
import _SERVICES from 'shared/constants/socialServices';
import { Claims } from 'components/Segments/UserCard';
import Videos from 'components/Segments/Videos';
import noo from 'api';

const WhiteBox = styled(Box)`
  background-color: #fafafa;
  border: 1px solid #ccc;
`;
export const ImageConstrainer = styled.div`
  > img {
    max-width: 100px;
    border: 1px solid #000;
    margin-top: 10px;
  }
`;

const ClaimProfile = props => {
  const { person_id } = useParams(); // onSubmit
  const { nooUser, currentNetworkData } = useNoo('ClaimProfile');
  const netname = currentNetworkData.id;
  const { nooCommandDirect, nooCommandResponseDirect } = useNooApi();
  const [localErrors, setLocalErrors] = useState(null);
  const [profileGraph, setProfileGraph] = useState({ profile: null, graph: null });
  const [profile, setProfile] = useState(null);
  const [token, setToken] = useState(null);
  const bookmarkletRef = useRef();

  const personId = useMemo(() => {
    return person_id ? 'Persons/' + person_id : nooUser?.person?._id;
  }, [person_id, nooUser?.person?._id]);

  const readPersonToken = useCallback(
    async pid => {
      if (!token) {
        fetch(`/api/gc_encrypt/${pid}`)
          .then(async response => {
            if (response.ok) {
              const json = await response.json();
              if (json.token) {
                setToken(json.token);
              }
              return json;
            } else {
              console.log('gc token resp NOT OK', response);
            }
          })
          .catch(error => {
            console.log('there was an error getting token', error);
            return { error };
          });
      }
    },
    [token]
  );

  useEffect(() => {
    if (token && bookmarkletRef?.current) {
      let it = ClaimBookmarklet.replace('LOGGEDINUSERID', token);
      if (netname == 'netcoop') {
        it = it.replace('nodal_sandbox', 'data_sandbox'); // 'spacetime_0'); //
        // it = it.replace('https://greencheck.world', 'http://localhost:3001');
      }
      bookmarkletRef.current.href = it;
    }
  }, [netname, token, bookmarkletRef]);

  const myBookmarklet = useMemo(() => {
    if (personId) {
      const json = readPersonToken(personId.replace('Persons/', '')) || {};
      let it = ClaimBookmarklet.replace('LOGGEDINUSERID', json.token || personId);
      if (netname == 'netcoop') {
        it = it.replace('nodal_sandbox', 'data_sandbox'); //  // 'spacetime_0'
      }
      return it;
    }
  }, [netname, personId, readPersonToken]);

  const bookmarklet = useMemo(() => {
    const image = '/public/images/greencheck_cropped.png';
    const bm_text = '';
    const prefix = location.host.indexOf('localhost') == 0 ? 'http://' : 'https://';
    const href = myBookmarklet;

    return (
      myBookmarklet && (
        <Tooltip label='Click-Drag this to your browser bookmark bar, and click it from your logged-in LinkedIn, Twitter, Github, Mastodon... pages.'>
          <Link
            href={href}
            ref={bookmarkletRef}
            color='white'
            display='inline-block'
            p='0 4px'
            backgroundColor={'lightgreen'}
            borderRadius='full'
          >
            <HStack>
              <Image src={image} alt={bm_text} width={'25px'} />
              <Text color='black'>{netname == 'netcoop' ? 'Net Co-op' : 'GreenCheck'} Claim</Text>
            </HStack>
          </Link>
        </Tooltip>
      )
    );
  }, [myBookmarklet, netname]);

  const available = Object.keys(_SERVICES)
    .map(svc => capitalize(svc))
    .join(', ');

  let links = [];
  let items = [];
  Object.entries(_SERVICES).forEach(pair => {
    const [svc, domain] = pair;
    items.push(<ServiceLink svc={svc} />);
    items.push(<span>, </span>);
  });

  return (
    <UserAuth unauthorized='signin'>
      <Heading as='h2' fontSize={'20px'}>
        Use this page to claim ownership of your various accounts.
      </Heading>
      {netname == 'netcoop' && <Text>Network Cooperative requires LinkedIn.</Text>}
      <Text>Browser only for now. It only takes a few minutes.</Text>
      {bookmarklet}{' '}
      <Heading as='h2' fontSize={'20px'}>
        Instructions
      </Heading>
      <UnorderedList>
        <ListItem key={1}>
          Watch the Tutorial video below and/or follow these simple instructions.
        </ListItem>
        <ListItem key={2}>
          Drag the green bookmarklet above ^ to your browser bookmarks bar. You may need to display
          the bar under your browser View menu.
        </ListItem>
        <ListItem key={3}>Then open tabs and log in for {items}... Others soon.</ListItem>
        <ListItem key={5}>
          Then, at each site, click the GreenCheck bookmark to claim that account.
        </ListItem>
        <ListItem key={6}>Reload this page to see your claims.</ListItem>
        <ListItem key={4}>
          PS: Any social site can be GreenCheck-claimable by including a{' '}
          <em>script id=&apos;greencheck&apos;</em> with logged-in user data as{' '}
          <Link href='https://weco.io/' isExternal>
            WeCo
          </Link>{' '}
          does.
        </ListItem>
      </UnorderedList>
      <Videos key='videos' />
      <div className='App-row listing' bg='white'>
        {profile && (
          <Flex sx={{ w: '100%' }}>
            <Heading as='h4'>{profile.fullname || profile.displayName}</Heading>
          </Flex>
        )}
        <Claims personId={personId} />
      </div>
    </UserAuth>
  );
};

export default ClaimProfile;
