const _SERVICES = {
  linkedin: 'linkedin.com',
  twitter: 'twitter.com',
  // facebook: 'facebook.com',
  // slack: 'slack.com',
  youtube: 'youtube.com',
  // discord: 'discord.com',
  // google: 'google.com',
  github: 'github.com',
  mastodon: 'mastodon.com',
  weco: 'weco.io',
  hylo: 'hylo.com',
  academia: 'independent.academia.edu',
  semantic: 'semanticscholar.org',
  scholar: 'scholar.google.com'
};
export const _SERVICE_PREFIXES = {
  linkedin: 'in/',
  weco: 'u/',
  semantic: 'author/{fullname}/',
  hylo: 'all/members/'
};
// also need to update server/lib/bmSocialClaimUI, server/lib/socialServices.js, node_normalizer, Timeline
// see semantic in graph_normalize.js
export default _SERVICES;
